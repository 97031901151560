<template>
  <div class="app">
    <AppHeader fixed>
      <SidebarToggler class="d-lg-none" display="md" mobile />
      <b-link class="navbar-brand" to="#">
        <img class="navbar-brand-full" src="/img/brand/logo.png" width="160" height="40" alt="Logo">
        <img class="navbar-brand-minimized" src="/img/brand/logo.png" width="160" height="40" alt="Logo">
      </b-link>
      <SidebarToggler class="d-md-down-none" display="lg" />
      <b-navbar-nav class="ml-auto">
        <NotificationDropdown />
        <DefaultHeaderDropdownAccnt />
      </b-navbar-nav>
    </AppHeader>
    <div class="app-body">
      <AppSidebar fixed>
        <SidebarHeader />
        <SidebarForm />
        <SidebarNav class="pb-5" :navItems="nav"></SidebarNav>
        <SidebarFooter />
        <SidebarMinimizer />
      </AppSidebar>
      <main class="main">
        <div class="container-fluid">
          <router-view></router-view>
        </div>
      </main>
    </div>
  </div>
</template>
<style src="vue-multiselect/dist/vue-multiselect.min.css"></style>

<script>
  import {
    Header as AppHeader,
    SidebarToggler,
    Sidebar as AppSidebar,
    SidebarFooter,
    SidebarForm,
    SidebarHeader,
    SidebarMinimizer,
    SidebarNav,
  } from '@coreui/vue'
	import api from '@/utils/api.js';
  import DefaultHeaderDropdownAccnt from './DefaultHeaderDropdownAccnt'
  import NotificationDropdown from '@/components/NotificationDropdown'
  import { mapGetters } from 'vuex';

  export default {
    name: 'DefaultContainer',
    components: {
      AppHeader,
      AppSidebar,
      DefaultHeaderDropdownAccnt,
      NotificationDropdown,
      SidebarForm,
      SidebarFooter,
      SidebarToggler,
      SidebarHeader,
      SidebarNav,
      SidebarMinimizer,
    },
    data() {
      return {
        user: {},
      }
    },
    created() {
      const _ = this;
      _.getProfile();
    },
    methods: {
      getProfile() {
        const _ = this;
        _.$axios.get(api.profile).then(res => {
          _.user = res.data.data;
          _.$store.dispatch('setUser', _.user);
        });
      },
    },
    computed: {
      ...mapGetters(['nav']),
      name() {
        return this.$route.name
      },
      list() {
        return this.$route.matched.filter((route) => route.meta.label)
      }
    }
  }
</script>