<template>
   <b-dropdown size="lg"  variant="link" toggle-class="text-decoration-none" no-caret left offset="50"  class="notification">
    <template #button-content>
      <div class="position-relative">
        <i class="fa fa-bell" style="color: #606684; font-size: 1.5em"></i>
        <span class="badge-custom position-absolute" style="top: -10px; left: 15px" v-if="config.total_unread > 0">{{config.total_unread}}</span>
        <span class="sr-only">Notifications</span>
      </div>
    </template>
    <div class="bg-secondary text-center py-3">
      <strong class="text-muted">Notifications</strong>
    </div>
    <div class="notification-container">
      <div v-if="config.rows.length">
        <b-dropdown-item v-for="(notif, index) in config.rows" :key="index" @click="readNotification(notif)" :class="{'bg-gray-100': !notif.is_read}">
          <strong>{{notif.title}}</strong>
          <p>
            {{notif.message}}
          </p>
        </b-dropdown-item>
      </div>
      <b-dropdown-item v-else class="text-center">
        No notifications
      </b-dropdown-item>
      <b-dropdown-item-button v-if="showButton" @click.native.capture.stop="showMore" class="text-center">Show More</b-dropdown-item-button>
    </div>
  </b-dropdown>
</template>

<script>
  import Pusher from 'pusher-js'
	import api from '@/utils/api.js'
  import {truncate} from '@/utils/helpers.js'
  import { mapState } from 'vuex'
  export default {
    name: 'NotificationDropdown',
    data() {
      return {
        truncate,
        user_id : localStorage.getItem('uid'),
        config: {
					api: api.notification,
					rows: [],
					total_data: 0,
					total_page: 0,
					per_page: 10,
					page: 1,
					orderby: "id",
					sort: "desc",
          total_unread: 0,
				}, 
      }
    },
    computed: {
      ...mapState(['user']),
      showButton() {
        return this.config.rows.length && this.config.total_data > 10 && this.config.rows.length !== this.config.total_data
      }
    },
    created() {
      this.get()
      this.subscribe()
    },
    methods: {
      subscribe () {
        let pusher = new Pusher(process.env.VUE_APP_KEY_PUSHER, { cluster: 'ap1' })
        pusher.subscribe('harian-analisa')
        pusher.bind('update-notif', data => {
          if(data.role === 'admin'){
            this.get();
          } else if(data.role === 'contributor' && data.user_id == this.user_id) {
            this.get();
          }
        })
      },
      get() {
				const params = {
            per_page: this.config.per_page,
            page: this.config.page,
            orderby: this.config.orderby,
            sort: this.config.sort,
        };
        return this.$axios.get(this.config.api, {
            params: Object.assign(params)
        }).then(res => {
            const {
                total_data,
                rows,
                total_page,
                total_unread
            } = res.data.data;
            this.config.rows = [...this.config.rows, ...rows];
            this.config.total_data = total_data;
            this.config.total_page = total_page;
            this.config.total_unread = total_unread;
        }).catch((error) => console.log(error));
			},

      showMore() {
        this.config.page++
        this.get()
      },

      readNotification(notification) {
        let {redirect_to, ref_id, id} = notification

        this.$axios.put(`${api.notification}/${id}`)
        
        const index = this.config.rows.findIndex(notif => {
          return notif.id === id;
        }); // 👉️ 1

        if (index !== -1) {
          this.config.rows[index].is_read = true;
          this.config.total_unread--
        }

        let url = ''
        switch (redirect_to) {
          case 'Detail Contributor':
            url = 'contributor'
            break;
          case 'Detail Membership':
            url = 'membership'
            break;
          case 'Detail Avertisement':
            url = 'advertisement'
            break;
          case 'Detail News':
            if(this.user.role.id === 2) {
              url = 'news'
            } else {
              url = 'article'
            }
            break;
          default:
            url = 'dashboard'
            ref_id = ''
            break;
        }
        const fullUrl = `/${url}/${ref_id}`
        if(fullUrl !== `${this.$route.path}/` && redirect_to) {
          this.$router.push(fullUrl)
        }   
      }
    }
  }
</script>

<style>
a.dropdown-item {
  white-space: unset !important;
}
.notification-container {
  max-height: 350px;
  width: 400px;
  overflow-y: auto;
  overflow-x: hidden;
}

.notification-container::-webkit-scrollbar {
  width: 4px;               /* width of the entire scrollbar */
}

.notification-container::-webkit-scrollbar-track {
  background: #F0F3F5;        /* color of the tracking area */
}

.notification-container::-webkit-scrollbar-thumb {
  background-color: rgb(184, 184, 184);    /* color of the scroll thumb */
  border-radius: 20px;       /* roundness of the scroll thumb */
  border: 1px solid rgb(184, 184, 184);  /* creates padding around scroll thumb */
}
.badge-custom {
  color: white;
  background-color: crimson;
  font-size: 10px;
  font-weight: 700;
  padding: 5px;
  min-width: 22px;
  min-height: 20px;
  border-radius: 100%;
}
</style>