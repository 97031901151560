<template>
  <AppHeaderDropdown no-caret v-if="user">
    <template slot="header">
      <img :src="user.image_profile" class="img-avatar" :alt="user.first_name" />
      <div class="username mr-25 pull-right text-left d-none d-md-block">
        <p>{{user.first_name}} {{user.last_name}}</p>
        <span v-if="user.role">{{user.role.name}}</span>
      </div>
    </template>
    <template slot="dropdown">
      <b-dropdown-header tag="div" class="text-center">
        <strong>Akun</strong>
      </b-dropdown-header>
      <div class="username text-center my-2 px-3">
        <p>{{user.first_name}} {{user.last_name}}</p>
        <span v-if="user.role">{{user.role.name}}</span>
      </div>
      <router-link role="menuitem" class="dropdown-item" to="/change-profile"><i class="fa fa-user" /> Change Profile</router-link>
      <router-link role="menuitem" class="dropdown-item" to="/change-password"><i class="fa fa-key" /> Change Password</router-link>
      <a role="menuitem" href="#" v-on:click.prevent="logout" class="dropdown-item"><i class="fa fa-sign-out" /> Logout</a>
    </template>
  </AppHeaderDropdown>
</template>

<script>
  import {
    HeaderDropdown as AppHeaderDropdown
  } from '@coreui/vue'
  import {
    mapGetters
  } from 'vuex'

  export default {
    name: 'DefaultHeaderDropdownAccnt',
    components: {
      AppHeaderDropdown
    },
    computed: {
      ...mapGetters({
        user: 'user'
      })
    },
    methods: {
      logout() {
        let _ = this
        _.$store.dispatch('logout')
        _.$router.push('/login')
      }
    }
  }
</script>